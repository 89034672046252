body {
    font-family: "Inter";
    padding-right: unset !important;
    overflow: unset !important;
    margin: 0px;
}

.bgcolorstyle {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.bgcolorstyle::before {
    content: '';
    background-color: #eeeeee42;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    width: 49.50%;
}

/* tabs */
.MuiTab-textColorPrimary.Mui-selected {
    color: #27292f !important;
    font-size: 14px !important;
    font-family: "Inter" !important;
}

.MuiTab-textColorPrimary {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    color: #6a7b8f;
    font-family: "Inter" !important;
}

/* .css-1aquho2-MuiTabs-indicator {
background-color: #27292f;
} */

.css-19mkjam-MuiPaper-root-MuiAccordion-root::before {
    background-color: transparent !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
    border-radius: 3px;
    background-color: #27292f !important;
    padding: 10px 15px;
    color: #fff !important;
}

.rbc-toolbar button {
    background-color: #edeced !important;
    padding: 10px 15px !important;
    border: solid 1px #edeced !important;
}

.rbc-header {
    font-weight: 600;
    font-size: 14px !important;
    padding: 10px 15px !important;
    background: #f0eded !important;
    height: 40px !important;
}

.rbc-time-view .rbc-row {
    min-height: unset !important;
}

.css-19kzrtu {
    padding: 0px !important;
}

.css-ja5taz-MuiTooltip-tooltip {
    margin-left: 5px !important;
    width: 70% !important;
}

.paddingcustom {
    padding: 5px 30px !important;
    font-size: 13px !important;
    min-height: 38px !important;
}

.paddingcustom.MuiTab-textColorPrimary.Mui-selected {
    font-size: 13px !important;
}

.rbc-event-content p {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 0;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
    background-color: #2196F3;
}

.rbc-row-content {
    overflow-y: scroll;
}

.rbc-row-content::-webkit-scrollbar {
    width: 5px;
}

.rbc-row-content::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 5px;
}

.rbc-row-content::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 5px;
}

.innertab-style {
    padding: 10px 15px !important;
    font-size: 14px !important;
    font-family: "Inter" !important;
}

:focus-visible {
    
}

.css-1ig1it4-MuiSlider-root {
    color: transparent !important;
}

.activemediatype {
    background-color: #000;
}

.activemediatype>img {
    webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.map-style svg {
    display: inline-block;
    vertical-align: middle;
}
.map-style path {
    fill: #2a354d;
}