.table {
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid black;
  padding: 10px;
  font-size:12px;
  text-align:left;
}

.table th {
  background-color: #f2f2f2;
  /* Example: light gray background for headers */
}

.table td button {
  margin-right: 5px;
}


.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.styled-table thead tr {
  background-color: #f4f4f4;
  color: #333;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  font-size:12px;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.icon-details {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.details-text {
  font-size: 12px;
}