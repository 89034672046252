.full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loader-spinner {
    /* Customize your spinner animation here */
    border: 10px solid #f3f3f3; /* Example spinner */
    border-radius: 50%;
    border-top-color: #3498db; /* Example spinner animation color */
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }